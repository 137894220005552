const getStoreList = `/gateway/hc-data/datacenter/store/page`;
const getStoreDetail =`/gateway/hc-data/datacenter/store/detail`;
const getStoreSubmit=`/gateway/hc-data/datacenter/store/submit`;
const removeStore=`/gateway/hc-data/datacenter/store/remove`;
//获取数据库类型
const getSelectList =`/gateway//hc-data/datacenter/source/select`
export { 
  getStoreList,
  getStoreDetail,
  getStoreSubmit,
  removeStore,
  getSelectList
};
